import {
  Checkbox,
  CircularProgress,
  Dialog,
  LinearProgress,
  Pagination,
} from "@mui/material";
import Button from "@mui/material/Button";
import dayjs from "dayjs";
import { BsPencilSquare } from "react-icons/bs";
import { FaPlus, FaRegFilePdf } from "react-icons/fa";
import { TbHexagonLetterT, TbHexagonLetterU } from "react-icons/tb";
import { VscDebugRestart } from "react-icons/vsc";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import iconOcultar from "../../../../assets/iconOcultar.svg";
import { Header } from "../../../Header";
import { ConfirmExclude } from "../ConfirmExclude";
import { ModalFinancialRelease } from "./ModalFinancialRelease";
import { ModalImport } from "./ModalImport";
import "./styles.scss";
import { ContentProps, useFinancialRelease } from "./useFinancialRelease";

export default function FinancialRelease() {
  const {
    financialRelease,
    handlePaginate,
    selectedFinancialRelease,
    handleGetFinancialRelease,
    setSelectedFinancialRelease,
    handleEditModal,
    modalState,
    setModalState,
    handleDeleteFinancialRelease,
    isLoading,
    handleImportRegisters,
    selectedImportRegisters,
    handleSubmitImport,
    page,
    allSelected,
    setAllSelected,
    setFilterRegisters,
    filterRegisters,
    handleGetBase64,
    installmentList,
    showModalExclude,
    setShowModalExclude,
    financeToDelete,
    setFinanceToDelete,
    setShowModalImport,
    showModalImport,
    selectedInstallment,
    setSelectedInstallment,
    setFinancialRelease,
  } = useFinancialRelease();
  return (
    <div className="FinancialRelease-container">
      <Header
        message={"Dashboard/Lançamentos Financeiros"}
        showAtualizacao={true}
      />
      <main>
        <div className="FinancialRelease-main-card">
          <h2>Lançamentos Financeiros</h2>

          <header className="FinancialRelease-main-card-header">
            <aside className="FinancialRelease-main-card-filters">
              <select
                onChange={(e) =>
                  setFilterRegisters((prevState) => ({
                    ...prevState,
                    tpStatus: e.target.value,
                  }))
                }
                value={filterRegisters?.tpStatus}
              >
                <option hidden>Status</option>
                <option value="">Nenhum</option>
                <option value="A">Aguardando</option>
                <option value="I">Importado</option>
                <option value="T">Transferido</option>
                <option value="E">Erro</option>
              </select>

              <input
                type="number"
                placeholder="Número documento"
                defaultValue={filterRegisters.numDocumento}
                onBlur={(e) =>
                  setFilterRegisters((prevState) => ({
                    ...prevState,
                    numDocumento: e.target.value,
                  }))
                }
              />
              <input
                type="number"
                placeholder="Valor"
                defaultValue={filterRegisters.valor}
                onBlur={(e) =>
                  setFilterRegisters((prevState) => ({
                    ...prevState,
                    valor: e.target.value,
                  }))
                }
              />
              <input
                type="date"
                placeholder="Número documento"
                defaultValue={filterRegisters.dataPagamento}
                onBlur={(e) =>
                  setFilterRegisters((prevState) => ({
                    ...prevState,
                    dataPagamento:
                      e.target.value !== ""
                        ? dayjs(e.target.value).format("YYYY-MM-DD")
                        : "",
                  }))
                }
              />
              <select
                onChange={(e) => {
                  setFilterRegisters({
                    ...filterRegisters,
                    prestacaoContaId: Number(e.target.value),
                  });
                  setSelectedInstallment(Number(e.target.value));
                }}
              >
                <option hidden>
                  {installmentList.find(
                    (install) =>
                      install?.prestacaoContaId ===
                      filterRegisters?.prestacaoContaId
                  )?.vigencia || installmentList[0]?.vigencia}
                </option>

                {installmentList.map((installment) => (
                  <option
                    key={installment.prestacaoContaId}
                    value={installment.prestacaoContaId}
                  >
                    {installment.vigencia}
                  </option>
                ))}
              </select>
              <button
                type="button"
                className="filter-button"
                onClick={() =>
                  handleGetFinancialRelease(
                    0,
                    filterRegisters.tpStatus,
                    filterRegisters.numDocumento,
                    filterRegisters.valor,
                    filterRegisters.dataPagamento,
                    filterRegisters.prestacaoContaId ||
                      installmentList[0].prestacaoContaId
                  )
                }
              >
                Filtrar
              </button>
            </aside>
            <div className="header-buttons">
              <aside>
                <Button
                  variant="contained"
                  onClick={() => setShowModalImport(true)}
                >
                  Importar Totvs <FaPlus />
                </Button>
              </aside>
              <aside>
                <Button variant="outlined" onClick={() => setModalState(true)}>
                  Novo Registro <FaPlus />
                </Button>
              </aside>
              <aside>
                <Button
                  variant="contained"
                  onClick={() => handleSubmitImport()}
                  disabled={!allSelected && selectedImportRegisters.length <= 0}
                >
                  Enviar Sicap <FaPlus />
                </Button>
                <Checkbox
                  color="primary"
                  size="small"
                  title="Todos Registros"
                  checked={allSelected}
                  onChange={() => setAllSelected(!allSelected)}
                />{" "}
                Todos Registros
              </aside>
              <div className="header-buttons-restart">
                {isLoading ? (
                  <CircularProgress
                    style={{
                      color: "white",
                      animation: "none",
                      display: "flex",
                      alignItems: "center",
                    }}
                  />
                ) : (
                  <VscDebugRestart
                    data-tip="Atualizar"
                    onClick={() =>
                      handleGetFinancialRelease(
                        page,
                        filterRegisters.tpStatus,
                        filterRegisters.numDocumento,
                        filterRegisters.valor,
                        filterRegisters.dataPagamento,
                        filterRegisters.prestacaoContaId ||
                          installmentList[0].prestacaoContaId
                      )
                    }
                  />
                )}
              </div>
            </div>
          </header>
          {/* <aside className="FinancialRelease-main-card-filters">
            <input type="text" />
            <input type="text" />
            <button type="button">Filtrar</button>
          </aside> */}
          <section className="FinancialRelease-main-card-section">
            <Dialog
              open={modalState}
              onClose={() => (
                setModalState(false),
                setSelectedFinancialRelease({} as ContentProps)
              )}
              maxWidth="lg"
              fullWidth={true}
              PaperProps={{
                style: {
                  backgroundColor: " hsl(206, 100%, 12%)",
                },
              }}
            >
              <ModalFinancialRelease
                prestacaoContaId={selectedInstallment}
                handleCloseModal={() => (
                  setModalState(false),
                  setSelectedFinancialRelease({} as ContentProps)
                )}
                selectedFinancialRelease={selectedFinancialRelease}
                handleGetFinancialRelease={() =>
                  handleGetFinancialRelease(
                    page,
                    filterRegisters.tpStatus,
                    filterRegisters.numDocumento,
                    filterRegisters.valor,
                    filterRegisters.dataPagamento,
                    filterRegisters.prestacaoContaId ||
                      installmentList[0].prestacaoContaId
                  )
                }
              />
            </Dialog>
            <Dialog
              open={showModalExclude}
              onClose={() => setShowModalExclude(false)}
              PaperProps={{
                style: {
                  backgroundColor: " hsl(206, 100%, 12%)",
                },
              }}
            >
              <ConfirmExclude
                handleCloseModal={() => setShowModalExclude(false)}
                handleSubmit={() =>
                  handleDeleteFinancialRelease(financeToDelete.cdDocFinanceiro)
                }
              />
            </Dialog>
            <Dialog
              open={showModalImport}
              onClose={() => setShowModalImport(false)}
              PaperProps={{
                style: {
                  backgroundColor: " hsl(206, 100%, 12%)",
                },
              }}
            >
              <ModalImport
                handleCloseModal={() => setShowModalImport(false)}
                installmentList={installmentList}
                setNewList={setFinancialRelease}
              />
            </Dialog>
            {!isLoading ? (
              <table>
                <thead>
                  <tr>
                    <th colSpan={1}></th>
                    <th>Status</th>
                    <th>Conta contábil bancaria</th>
                    <th>Valor</th>
                    <th>Numero documento</th>
                    <th>Numero pagamento</th>
                    <th>Vencimento</th>
                    <th>Data emissao</th>
                    <th>Data pagamento</th>
                    <th colSpan={1}></th>
                  </tr>
                </thead>
                <tbody>
                  {financialRelease?.content?.map((finances, index) => (
                    <tr key={index}>
                      <td>
                        {(finances.tpStatus === "A" ||
                          finances.tpStatus === "E") && (
                          <Checkbox
                            color="primary"
                            size="small"
                            onChange={() => {
                              handleImportRegisters(finances.cdDocFinanceiro);
                            }}
                            checked={selectedImportRegisters.some(
                              (item) => item === finances.cdDocFinanceiro
                            )}
                          />
                        )}
                      </td>

                      <td>
                        {finances.tpStatus === "A" && (
                          <div className="circle-status">
                            <span
                              data-tip="Aguardando"
                              className="circle-aguardando"
                            ></span>
                            <ReactTooltip
                              place="top"
                              type="light"
                              effect="solid"
                            />
                          </div>
                        )}
                        {finances.tpStatus === "I" && (
                          <div className="circle-status">
                            <span
                              data-tip="Importado"
                              className="circle-importado"
                            ></span>
                            <ReactTooltip
                              place="top"
                              type="light"
                              effect="solid"
                            />
                          </div>
                        )}
                        {finances.tpStatus === "T" && (
                          <div className="circle-status">
                            <span
                              data-tip="Transferido"
                              className="circle-transferido"
                            ></span>
                            <ReactTooltip
                              place="top"
                              type="light"
                              effect="solid"
                            />
                          </div>
                        )}
                        {finances.tpStatus === "E" && (
                          <div className="circle-status">
                            <span
                              data-tip={finances.dsErro}
                              className="circle-error"
                            ></span>
                            <ReactTooltip
                              place="top"
                              type="light"
                              effect="solid"
                            />
                          </div>
                        )}
                        {finances.tpStatus === "F" && (
                          <div className="circle-status">
                            <span
                              data-tip="Finalizado"
                              className="circle-finalizado"
                            ></span>
                            <ReactTooltip
                              place="top"
                              type="light"
                              effect="solid"
                            />
                          </div>
                        )}
                      </td>
                      <td>{finances.contaContabilBancaria}</td>
                      <td>{finances.valor}</td>
                      <td>{finances.numDocumento}</td>
                      <td>{finances.numPagamento}</td>
                      <td>
                        {finances.vencimento !== null
                          ? dayjs(finances.vencimento).format("DD/MM/YYYY")
                          : ""}
                      </td>
                      <td>
                        {finances.dataEmissao !== null
                          ? dayjs(finances.dataEmissao).format("DD/MM/YYYY")
                          : ""}
                      </td>
                      <td>
                        {finances.dataPagamento !== null
                          ? dayjs(finances.dataPagamento).format("DD/MM/YYYY")
                          : ""}
                      </td>
                      <td>
                        <div className="FinancialRelease-main-card-section-icons">
                          {finances.contemArquivo && (
                            <p
                              data-tip="Baixar PDF"
                              onClick={() =>
                                handleGetBase64(
                                  finances.cdDocFinanceiro,
                                  finances.dsNomeArquivoNf
                                )
                              }
                            >
                              <FaRegFilePdf color="white" />
                            </p>
                          )}
                          <ReactTooltip
                            place="top"
                            type="light"
                            effect="solid"
                          />
                          <Link
                            to={`/LancamentosFinanceiros/TributosRetidos/${
                              finances.numPagamento
                            }/${
                              filterRegisters.prestacaoContaId ||
                              selectedInstallment
                            }`}
                          >
                            <p data-tip="Tributos Retidos">
                              <TbHexagonLetterT />
                            </p>
                          </Link>
                          <ReactTooltip
                            place="top"
                            type="light"
                            effect="solid"
                          />

                          <Link
                            to={`/LancamentosFinanceiros/AggregateUnits/${
                              finances.numPagamento
                            }/${
                              filterRegisters.prestacaoContaId ||
                              selectedInstallment
                            }`}
                          >
                            <p data-tip="Unidades Agregadas">
                              <TbHexagonLetterU />
                            </p>
                          </Link>
                          <ReactTooltip
                            place="top"
                            type="light"
                            effect="solid"
                          />
                          <p
                            data-tip="Editar Registro"
                            onClick={() => handleEditModal(finances)}
                          >
                            <BsPencilSquare color="#D8A511" />
                          </p>
                          <ReactTooltip
                            place="top"
                            type="light"
                            effect="solid"
                          />
                          <p
                            data-tip="Excluir Registro"
                            onClick={() => (
                              setFinanceToDelete(finances),
                              setShowModalExclude(true)
                            )}
                          >
                            <img src={iconOcultar} alt="" />
                          </p>
                          <ReactTooltip
                            place="top"
                            type="light"
                            effect="solid"
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <LinearProgress />
            )}
          </section>
          <footer>
            <Pagination
              count={financialRelease.totalPages}
              variant="outlined"
              shape="rounded"
              onChange={handlePaginate}
              page={page + 1}
            />
          </footer>
        </div>
      </main>
    </div>
  );
}
